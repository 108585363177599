<template>
  <div class="box">
    <div class="top">
      <b-navbar toggleable="lg" type="dark" variant="dark" fixed="top">
        <b-navbar-brand id="top-logo">
          <img  class="image_1" referrerpolicy="no-referrer" src="../assets/img/top-logo.png"/> 
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse class="nav-collapse" id="nav-collapse" is-nav>
          <b-navbar-nav class="top-right ms-auto">
            <b-nav-item  v-for="(item,index) in menuList" :key="index" @click="selectMenu(item.id, item.url)" >
              <b-nav-item class="top-right-select" v-if="menuIndex==item.id">
                <span class="top-right-item">{{ item.text }}</span>
              </b-nav-item>
              <b-nav-item  v-else>
                <span class="top-right-item">{{ item.text }}</span>
              </b-nav-item>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

    <div class="header">
        <!-- 5种banner切换 首页-->
        <div class="top-banner home-banner flex-col" v-if="menuIndex==1">
          <div class="text-banner flex-col justify-between">
            <div class="banner_sketch">AI+网络通信设计</div>
            <div class="banner_info">AI+network&nbsp;communication&nbsp;design</div>
          </div>
        </div>
        <!-- 设计平台-->
        <div class="top-banner design-banner flex-col" v-if="menuIndex==2">
          <div class="text-banner flex-col justify-between">
            <div class="banner_sketch">多种比选设计方案<br>全套智能生成</div>
            <div class="banner_info design-info1">A full set of comparison and selection design</div>
            <div class="banner_info design-info">schemes is automatically generated</div>
          </div>
        </div>
         <!-- 动态咨询-->
        <div class="top-banner consult-banner flex-col" v-if="menuIndex==3">
          <div class="text-banner flex-col justify-between">
            <div class="banner_sketch">动态资讯</div>
            <div class="banner_info">Dynamic&nbsp;information</div>
          </div>
        </div>
         <!-- 招贤纳士-->
        <div class="top-banner recruit-banner flex-col" v-if="menuIndex==4">
          <div class="text-banner flex-col justify-between">
            <div class="banner_sketch">招贤纳士</div>
            <div class="banner_info">invite to one 's side men of wisdom and valor</div>
          </div>
        </div>
         <!-- 关于我们-->
        <div class="top-banner about-banner flex-col" v-if="menuIndex==5">
          <div class="text-banner flex-col justify-between">
            <div class="banner_sketch about-us-sketch">关于我们</div>
            <div class="banner_info about-us-info">About us</div>
          </div>
        </div>
      </div>
    
    <section class="container-body">
      <router-view/>
    </section>
    <Footer @toguid="toguid"></Footer>
  </div>
</template>
<script>
import Footer from "./common/Footer";
export default {
  name:'Home',
  data() {
    return {
      menuIndex: 1,
      toId: "",
      menuList: [
          {text: '首页', id: 1, url: ''},
          {text: 'AI室分设计平台', id: 2, url: 'CompartmentDesign'},
          {text: '动态资讯', id: 3, url: 'InformationList'},
          {text: '招贤纳士', id: 4, url: 'RecruitInfo'},
          {text: '关于我们', id: 5, url: 'AboutUs'},
      ],
      backgroundImg: [
          require('../assets/img/home-banner.png'),
          require('../assets/img/design-banner.png'),
          require('../assets/img/consult-banner.png'),
          require('../assets/img/recruit-banner.png'),
          require('../assets/img/about-banner.png'),
      ],
      constants: {}
    };
  },
  components: {
		Footer
	},
  created() {
    this.menuIndex = localStorage.getItem("menuIndex");
    // this.toId = localStorage.getItem("toId");
    // console.log("menuIndex：" +  this.menuIndex)
    // console.log("toId：" +  this.toId)

  },
  methods: {
    selectMenu(index, url) {
      console.log("页面：" +  this.menuIndex)
      // if(this.menuIndex==null){
      console.log("模块：" + index)
      this.menuIndex = index;
      // }
      window.location.href = this.baseUrl + url;
		},
    toguid(path, id, pageIndex){
      localStorage.setItem('toId',id);
      this.selectMenu(pageIndex, path);
    },
  },
};
</script>
<style scoped lang="css" src="../assets/js/home.css" />