<template>
<div class="pre-footer" id="contact">
        <div class="container">
            <div class="row">
                <!-- BEGIN BOTTOM ABOUT BLOCK -->
                <div class="col-md-4 col-sm-6 pre-footer-col">
                    <h2>
                      <img class="image_log" referrerpolicy="no-referrer" src="../../assets/img/top-logo.png"/>
                    </h2>
                    <p></p>
                    <p class="margin-bottom-20" style="font-size: 12px">
                      ©2022-2023&nbsp; All&nbsp;rights&nbsp;reserved.&nbsp;&nbsp;
                      <br/>
                      <a class="footer-b" href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2023002593号-1</a>
                    </p>
                </div>
                <!-- END BOTTOM ABOUT BLOCK -->
                <!-- BEGIN TWITTER BLOCK --> 
                <div class="col-md-2 col-sm-6 pre-footer-col">
                    <h2 class="margin-bottom-0 mouse-change" @click="toguid('AboutUs','',5)">关于</h2>
                    <p></p>
                    <p>企业简介</p>
                    <p>企业文化</p>
                    <!-- <p @click="toguid('AboutUs','anchor-point-blurb',5)">企业简介</p>
                    <p @click="toguid('AboutUs','anchor-point-culture',5)">企业文化</p>      -->
                </div>
                <!-- END TWITTER BLOCK -->
                <div class="col-md-2 col-sm-6 pre-footer-col">
                    <!-- BEGIN BOTTOM CONTACTS -->
                    <h2 class="mouse-change" @click="toguid('CompartmentDesign','',2)">产品</h2>
                </div>
                <div class="col-md-2 col-sm-6 pre-footer-col">
                    <!-- BEGIN BOTTOM CONTACTS -->
                    <h2 class="mouse-change" @click="toguid('RecruitInfo','',4)">联系</h2>
                    <p></p>
                    <p>联系方式</p>
                    <p>招贤纳士</p> 
                </div>
                <div class="col-md-2 col-sm-6 pre-footer-col">
                    <!-- BEGIN BOTTOM CONTACTS -->
                    <!-- <h2> -->
                      <img class="image_qrCode" referrerpolicy="no-referrer" src="../../assets/img/ty-qrCode.png" />
                    <!-- </h2> -->
                </div>
            </div>
        </div>
    </div>
    <!-- END PRE-FOOTER -->
</template>

<script>

export default {
    name: "Footer",
    data() {
        return {
            year: "",
            url: 'http://beian.miit.gov.cn/state/outPortal/loginPortal.action',
        }
    },
    created() {
        this.year = new Date().getFullYear();
    },
    methods: {

        toguid(path, id, pageIndex){
            this.$emit("toguid", path, id, pageIndex);
        }

    }
}
</script>

<style scoped lang="css" src="../../assets/js/common/footer.css" />